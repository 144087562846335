import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Link from '../../components/Common/Link'
import Button from '../../components/button/button'
import { toLink } from '../../utils'
import { toolsLink } from '../../data'
import * as s from '../../pages/news.module.scss'

const ToolsFreePage: React.FC = () => {
  const description =
    'SIGNAL TOOLS - плагин для создания строительной модели и контроля объема выполненных работ. FREE версия включает плагины SIGNAL\u00A0for\u00A0Revit\u00A0(S4R) Free и Signal\u00A0for\u00A0Navis\u00A0(S4N) Free, которые позволяют получать объемы из модели и отмечать факт выполнения работ.'
  return (
    <>
      <SEO title="TOOLS FREE" description={description} />
      <NewsSection
        title="Мы выпустили бесплатную версию TOOLS - TOOLS FREE"
        dateData={{
          date: '26.07.2023',
        }}
        rayContent
      >
        <p className={s.text}>{description}</p>
        <h2 className={s.textTitle}>S4R free</h2>
        <StaticImage
          width={1160}
          className={cn(s.imageBlock, 'my-4')}
          src="../../assets/images/news/tools-free/image-1.jpg"
          alt="S4R free"
          title="S4R free"
          placeholder="blurred"
        />
        <p className={s.text}>
          Во Free версии плагина для Revit есть необходимый функционал по
          созданию строительной модели (СТМ) и нарезке выполненных работ
          согласно захваткам, т.е. вы можете фиксировать (переносить) элементы
          из проектной модели в СТМ со всеми нужными вам параметрами, копировать
          оси/\u200Dуровни.
        </p>
        <p className={s.text}>
          Далее, по выбору, либо сразу в Revit отмечаете дату выполнения
          элементов на СТМ (Завершить/\u200DПринять/\u200DЗапланировать) и
          экспортируете в *NWC, либо просто переключаете стадию у элементов на
          "Выполнено", экспортируете в *NWC и уже в Navisworks отмечаете дату
          выполнения. (на\u00A0ваш\u00A0выбор,\u00A0как\u00A0удобно)
        </p>
        <p className={s.text}>
          Команды Расчета (Объем/\u200DПлощадь/\u200DДлина) тоже остаются, можно
          посмотреть расчетные параметры элементов.
        </p>
        <p className={s.text}>
          Также появилась новая функция{' '}
          <Link
            href="https://docs.google.com/document/d/1_-uhnyjWhz0MGJhFG67TURhtMFwLMdvZ3uTaclPjeTU/edit#heading=h.m9tvkqi26uko"
            rel="nofollow noreferrer"
            target="_blank"
          >
            "Создание поверхностей"
          </Link>
          , она позволяет строить поверхности по граням: это удобно, например,
          для создания многослойных фасадов по голым бетонным стенам -
          прокликиваете грани с нужным материалом и получаете готовый фасад с
          параметрами, на котором также можно отмечать даты выполнения.
        </p>
        <h2 className={s.textTitle}>S4N free</h2>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'my-4')}
            src="../../assets/images/news/tools-free/image-2.jpg"
            alt="S4N free"
            title="S4N free"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          В плагине для Navisworks мы оставили все для взаимодействия с СТМ. Вы
          можете проставлять даты выполнения
          (Завершить/\u200DПринять/\u200DЗапланировать), оставлять комментарии в
          элементах.
        </p>
        <p className={s.text}>
          Кнопка "Копировать из R" позволяет копировать все параметры из Revit,
          если вы там проставляете факт выполнения. Это удобно для дальнейшей
          работы с моделью.
        </p>
        <p className={s.text}>
          Остаются Команды расчета, чтобы посмотреть Объем, Площадь и т.д. К
          этим командам добавилась одна команда{' '}
          <Link
            href="https://wiki.sgnl.pro/books/35-tools/page/1-naznacenie-komand-tools-for-navisworks#bkmrk-custom"
            rel="nofollow noreferrer"
            target="_blank"
          >
            ПОЛЬЗ
          </Link>
          . В ней вы можете создавать свои количественные параметры со своими
          коэффициентами, например, чтобы измерить опалубку в квадратных метрах
          по монолитным конструкциям.
        </p>
        <p className={s.text}>
          Также остается раскраска элементов (Цвет) и раскраска элементов по
          сценариям - раскрасить элементы с комментариями, или те которые были
          выполнены или просто по пользовательскому цвету.
        </p>
        <p className={s.text}>
          Фото360 - делает фотки по Точкам обзора, которые можно загрузить в наш
          SIGNAL\u00A0INSPECTION и сравнить модель с тем, что сделали на
          стройплощадке.
        </p>
        <p className={s.text}>
          Удалить - удаляет все свойства из вкладки SIGNAL.
        </p>
        <p className={s.text}>
          Плюс, мы перенесли Виды работ в Панель SIGNAL, теперь они под рукой и
          не блокируют все действия в Navis'e. В этой же панели оставили
          Свойства SIGNAL, чтобы долго в стандартных свойствах их не искать,
          Документы, которые можно к файлам привязать
          (КС-2,\u00A0Акты,\u00A0ИД\u00A0и\u00A0др.) и польз. свойства.
        </p>
        <div className="w-100 flex justify-center flex-col items-center">
          <Button className="my-4" onClick={() => toLink(toolsLink)}>
            Скачать
          </Button>
          <p className={s.text}>
            *Для скачивания плагина необходимо пройти регистрацию
          </p>
        </div>
      </NewsSection>
    </>
  )
}

export default ToolsFreePage
